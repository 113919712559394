@import "theme-less-vars";

.modal-page {

  &.ok {
    background-color: @base-color;
  }

  &.danger {
    background-color: @base-color;
  }

  &.error {
    background-color: @error-color;
  }

  &.warning {
    background-color: @warning-color;
  }

  &.info {
    background-color: @info-color;
  }

  &-header {
    display: flex; 
    justify-content: space-between;
    gap: 1rem;
  }

  .modal-box {
    @padding: 60px;
    display: flex;
    position: relative;
    flex-direction: column;
    align-content: space-between;
    border: 1px solid #999;
    border-radius: 6px;
    background-color: @background-color;

    margin-top: 5%;
    max-width: 800px;
    padding: @padding;
    min-height: 400px;

    @media screen and (max-width: @screen-xs-max) {
      @margin: 10px;
      max-width: calc(100% - 2 * @margin);
      max-height: calc(100% - 2 * @margin);
      margin-top: @margin;
      padding: @padding / 2;
    }

    > header {
      border-bottom: 1px solid @neutral-color-20;
      padding-bottom: @gutter-width;
      margin-bottom: @gutter-width;

      // .logo {
      //   max-width: 400px;

      //   @media screen and (max-width: @screen-xs-max) {
      //     max-width: 200px;
      //   }
      // }

      #gsvlogo {
        max-height: 90px;
        width: 100%;
        min-width: 40px;
        padding-top: 3px;
        padding-bottom: 2px;
        // max-height: 113px;
      //   padding-top: 4px;
      //   max-width: 148px;
      //   @media screen and (max-width: @screen-xs-max) {
      //     max-height: 58px;
      //     max-width: 83px!important;
      //   }
      }

      #politiet-logo {
        width: 100%;
        min-width: 150px;
      }
    }

    > footer {
      hr {
        width: 30%;
        text-align: left;
      }
    }
  }
}
