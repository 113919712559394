@import "theme-less-vars";

.full-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 30px;
}

.demographics {
  .action {
    text-align: right;
  }

  th {
    width: 20ex !important;
    text-align: right !important;
    padding-right: 5px !important;
  }
}

.person > .card {
  margin: 0
}

.template-debug {
  display: none;
}

[admin] {
  display: none;
}


.btn-admin {
  background-color: #cef!important;
}


main.profilepage {
  margin-top: 20px;
}

.action-box {
  padding: 10px;
}

.superuser-admin {
  margin: 0 !important;
}

.status-dot {
  display: inline-block;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: @neutral-color-5;
  vertical-align: baseline;
  text-align: center;
  font-size: $height * 0.84;

  &.passed {
    background-color: @theme-green;
    color: @theme-green-text;
  }

  //&.failed { background-color: @warning-color; }
  &.sm {
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 15px;
  }

  ~ .small {
    padding-left: $width * 1.18;
  }
}

.action-button {
  min-width: 140px;
}

#politiet-logo, #gsvlogo {
  width: 100%;
}

#politiet-logo {
  @media screen and (max-width: @screen-xs-max) {
    width: 150px;
  }
}

#gsvlogo {
  @media screen and (max-width: @screen-xs-max) {
    width: 36px;
  }
}

