/*
    This file is for calculating values for
    the theme-cssvars-defaults file.

    You should not import this file anywhere, just copy
    the generated values.
 */
:root {
    --grensevakt-basecolor: #00263E; // grensevakt-dark-blue-500
    --grensevakt-dark-blue-400: #3E5B6E;
    --grensevakt-dark-blue-300: #7B909D;
    --grensevakt-secondary-color: #4b5363;
    --grensevakt-background-color: #f9f9f9;
    --grensevakt-yellow-color: #FDDA25;
    --grensevakt-blue-color: #00569C;
    --grensevakt-widget-background-color: #fefefe;
    --grensevakt-neutral-color-3: #e5e9f0;
    --grensevakt-neutral-color-5: #f2f2f2;
    --grensevakt-neutral-color-10: #e6e6e6;
    --grensevakt-neutral-color-20: #cccccc;
    --grensevakt-neutral-color-40: #999999;
    --grensevakt-neutral-color-60: #666666;
    --grensevakt-neutral-color-80: #333333;
    --grensevakt-neutral-color-100: #000000;
    --grensevakt-theme-color-10: #e6f0f5;
    --grensevakt-theme-color-20: #cce0eb;
    --grensevakt-theme-color-40: #87b7cf;
    --grensevakt-theme-color-60: #66a3c2;
    --grensevakt-theme-color-80: #3384ad;
    --grensevakt-theme-color-100: #006699;
    --grensevakt-contrast-color-10: #e6f5f1;
    --grensevakt-contrast-color-20: #ccebe3;
    --grensevakt-contrast-color-40: #87cfbe;
    --grensevakt-contrast-color-60: #66c2ac;
    --grensevakt-contrast-color-80: #33ad90;
    --grensevakt-contrast-color-100: #009975;
}
