.modal-page.ok {
  background-color: var(--grensevakt-basecolor);
}
.modal-page.danger {
  background-color: var(--grensevakt-basecolor);
}
.modal-page.error {
  background-color: #e45d50;
}
.modal-page.warning {
  background-color: #e6a65d;
}
.modal-page.info {
  background-color: var(--grensevakt-basecolor);
}
.modal-page-header {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.modal-page .modal-box {
  display: flex;
  position: relative;
  flex-direction: column;
  align-content: space-between;
  border: 1px solid #999;
  border-radius: 6px;
  background-color: var(--grensevakt-background-color);
  margin-top: 5%;
  max-width: 800px;
  padding: 60px;
  min-height: 400px;
}
@media screen and (max-width: 767px) {
  .modal-page .modal-box {
    max-width: calc(100% - 2 * 10px);
    max-height: calc(100% - 2 * 10px);
    margin-top: 10px;
    padding: 60px / 2;
  }
}
.modal-page .modal-box > header {
  border-bottom: 1px solid var(--grensevakt-neutral-color-20);
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.modal-page .modal-box > header #gsvlogo {
  max-height: 90px;
  width: 100%;
  min-width: 40px;
  padding-top: 3px;
  padding-bottom: 2px;
}
.modal-page .modal-box > header #politiet-logo {
  width: 100%;
  min-width: 150px;
}
.modal-page .modal-box > footer hr {
  width: 30%;
  text-align: left;
}
.errordescr {
  color: red;
}
/*
    This file is for calculating values for
    the theme-cssvars-defaults file.

    You should not import this file anywhere, just copy
    the generated values.
 */
:root {
  --grensevakt-basecolor: #00263E;
  --grensevakt-dark-blue-400: #3E5B6E;
  --grensevakt-dark-blue-300: #7B909D;
  --grensevakt-secondary-color: #4b5363;
  --grensevakt-background-color: #f9f9f9;
  --grensevakt-yellow-color: #FDDA25;
  --grensevakt-blue-color: #00569C;
  --grensevakt-widget-background-color: #fefefe;
  --grensevakt-neutral-color-3: #e5e9f0;
  --grensevakt-neutral-color-5: #f2f2f2;
  --grensevakt-neutral-color-10: #e6e6e6;
  --grensevakt-neutral-color-20: #cccccc;
  --grensevakt-neutral-color-40: #999999;
  --grensevakt-neutral-color-60: #666666;
  --grensevakt-neutral-color-80: #333333;
  --grensevakt-neutral-color-100: #000000;
  --grensevakt-theme-color-10: #e6f0f5;
  --grensevakt-theme-color-20: #cce0eb;
  --grensevakt-theme-color-40: #87b7cf;
  --grensevakt-theme-color-60: #66a3c2;
  --grensevakt-theme-color-80: #3384ad;
  --grensevakt-theme-color-100: #006699;
  --grensevakt-contrast-color-10: #e6f5f1;
  --grensevakt-contrast-color-20: #ccebe3;
  --grensevakt-contrast-color-40: #87cfbe;
  --grensevakt-contrast-color-60: #66c2ac;
  --grensevakt-contrast-color-80: #33ad90;
  --grensevakt-contrast-color-100: #009975;
}
.full-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 30px;
}
.demographics .action {
  text-align: right;
}
.demographics th {
  width: 20ex !important;
  text-align: right !important;
  padding-right: 5px !important;
}
.person > .card {
  margin: 0;
}
.template-debug {
  display: none;
}
[admin] {
  display: none;
}
.btn-admin {
  background-color: #cef !important;
}
main.profilepage {
  margin-top: 20px;
}
.action-box {
  padding: 10px;
}
.superuser-admin {
  margin: 0 !important;
}
.status-dot {
  display: inline-block;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: var(--grensevakt-neutral-color-5);
  vertical-align: baseline;
  text-align: center;
  font-size: 42px;
}
.status-dot.passed {
  background-color: #3A703A;
  color: var(--grensevakt-neutral-color-3);
}
.status-dot.sm {
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 15px;
}
.status-dot ~ .small {
  padding-left: 59px;
}
.action-button {
  min-width: 140px;
}
#politiet-logo,
#gsvlogo {
  width: 100%;
}
@media screen and (max-width: 767px) {
  #politiet-logo {
    width: 150px;
  }
}
@media screen and (max-width: 767px) {
  #gsvlogo {
    width: 36px;
  }
}
.registration #id_consent {
  margin-left: 0;
}
.registration div[field="consent"] label:after {
  content: "" !important;
}
.registration div[field="consent"] .dvCheckboxInput .checkbox {
  text-align: right;
}
@media screen and (max-width: 768px) {
  .registration div[field="consent"] .dvCheckboxInput .checkbox {
    text-align: left;
  }
}
.depth-0 {
  box-shadow: none;
}
.depth-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.depth-2 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.depth-3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.depth-4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.depth-5 {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}
.card-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.card {
  padding: 15px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 2px;
  position: relative;
  overflow: hidden;
  margin: 15px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.card h1,
.card h2,
.card h3,
.card h4,
.card h5,
.card h6 {
  margin-top: 0;
}
.card h1 > small,
.card h2 > small,
.card h3 > small,
.card h4 > small,
.card h5 > small,
.card h6 > small {
  display: block;
  clear: both;
  margin-top: 0.3ex;
}
.card > header {
  margin-bottom: 1ex;
}
.card h1 {
  font-size: 30px;
}
.card h2 {
  font-size: 27px;
}
.card h3 {
  font-size: 24px;
}
.card h4 {
  font-size: 21px;
  font-weight: normal;
}
.card h5 {
  font-size: 21px;
}
.card h6 {
  font-size: 21px;
}
@media screen and (max-width: 767px) {
  .card {
    margin: 5px;
    padding: 5px;
  }
  .card h1 {
    font-size: 20px;
  }
  .card h2 {
    font-size: 18px;
  }
  .card h3 {
    font-size: 16px;
  }
  .card h4 {
    font-size: 14px;
  }
  .card h5 {
    font-size: 14px;
  }
  .card h6 {
    font-size: 14px;
  }
}
