@import "theme-less-vars";

.registration {
  #id_consent {
    margin-left: 0;
  }

  div[field="consent"] {
    label:after {
      content: "" !important;
    }

    .dvCheckboxInput .checkbox {
      text-align: right;
      @media screen and (max-width: @screen-sm) {
        text-align: left;
      }
    }
  }
}
